import React, { useContext } from 'react'
import Container from 'react-bootstrap/Container'
import ProgramContext from '../../context/ProgramContext'
import MySpinner from '../utilities/MySpinner'

const QRCode = () => {
  const { qrSheets } = useContext(ProgramContext)

  if(!qrSheets) return <MySpinner />

  return (
    <div>
      <h1 className='text-center mb-3 qr-header'>Genres</h1>
      <Container className='qr-container'>
        { console.log(qrSheets) }
        { qrSheets.map((sheet, index) => {
          return (
            <a href={sheet.pdf} target='_blank' rel='noreferrer' aria-label={`Link to download PDF about APT Worldwide content relating to ${sheet.label}`}download>
              <div key={index} className='mb-3'>
                <img src={sheet.img} alt={`Printable PDF for APT Worldwide ${sheet.name}`} />
                <h4>{sheet.name}</h4>
              </div>
            </a>
          )
        })}     
      </Container>
    </div>
  )
}

export default QRCode
