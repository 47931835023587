import { useEffect, useState } from 'react'
import { getGenreUrl } from '../utilities/helpers'
import MySpinner from '../utilities/MySpinner'

const MobileTombstone = ({program}) => {
  const [description, setDescription] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [ urlSlug, setUrlSlug ] = useState()

  useEffect(() => {
    if (program && program.genre && program.genre.length > 0) {
      // Map each genre to its URL slug
      const slugs = program.genre.map(genre => getGenreUrl(genre));
      setUrlSlug(slugs);
    } else {
      // Reset or clear slugs if no genres are present
      setUrlSlug([]);
    }
  }, [program])

  useEffect(() => {
    const encodedText = program.longDesc
    const decodedText = decodeURIComponent(encodedText.replace(/\+/g, ' '))
    const cleanText = decodedText.replace(/<br\s*\/?>/gi, '\n').replace(/<\/?b>/gi, '').replace(/<\/?i>/gi, '')
    const textArray = cleanText.split('\n')
    setDescription(textArray)
  },[program])

  // Generate JSON-LD schema object
  const jsonLdSchema = {
    "@context": "https://schema.org",
    "@type": "TVSeries",
    "@id": `#${program.idNumber}`,
    "abstract": program.shortDesc,
    "keywords": program.mtKeys,
    "name": program.featuredTitle,
    "numberOfEpisodes": program.epCount.replace(/\//g, 'x'),
    "genre": program.genre,
    "datePublished": program.prodYears,
    "trailer": program.sF,
    "videoFormat": program.vQ[0]
  }
  
  if(!program) <MySpinner />

  return (
    <div className='mobile-tombstone pb-3'>
      {/* JSON-LD Script */}
      <script type="application/ld+json">
        {JSON.stringify(jsonLdSchema)}
      </script>
      <div className='mobile-tombstone-metadata'>
      <section className='metadata-key'>Episodes & Length:</section>
      <section className='metadata-field'>{program.epCount.replace(/\//g, 'x')}</section>
        { program.genre.map((g, index, array) => {
          return (
            <>
              <section className='metadata-key'>Genre:</section>
              <section className='metadata-field' itemprop='genre'>{g}</section>
            </>
          )
        })}
      </div>
      <div className='program-description'>
       <div itemprop='abstract'>        
        {description.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        </div>
        <div className='program-press'>
          {program.pA?.map(press => (
            <a href={press.url} target='_blank' rel='noreferrer' itemprop='review'><p>{press.pressTitle}</p></a>
          ))}
        </div>
      </div>
    </div>
  )
}
export default MobileTombstone
