import React from 'react'
import { handleImageError, rearrangeString } from '../utilities/helpers'
import { Link } from 'react-router-dom'

const GenreHero = ({ genre, billboard }) => {

  return (
    <Link 
      to={`/programs/${billboard.link.substring(6)}`}
      state={{ billboard }} 
      aria-label={`Learn more about ${rearrangeString(billboard.DisplayTitle)}`}
    >
      <div className='hero-container' role='article'>
        <div className='hero-img-wrapper' key={genre.id}>
          <img 
            src={billboard.ImageLink} 
            alt={`Cover for ${rearrangeString(billboard.DisplayTitle)}`} 
            style={{ paddingTop: '50px'}}
            className='cover'
            loading='lazy'
            onError={handleImageError}
            />
          <div className='long-title'>
            {rearrangeString(billboard.DisplayTitle)}
          </div>
        </div>
      </div>
    </Link>
  )
}
export default GenreHero
